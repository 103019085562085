import React from "react"

import "../styles/style.scss"
import {
  Layout,
  Hero,
  Heading,
  HeroBody,
  Image,
  Container,
  Section,
  Content,
  PageHero,
  Video,
} from "../components/Components"

import { navigation } from "../common/navigation"

const StrategyPage = () => {
  return (
    <Layout>
      <PageHero
        title="Strategic Communication Platform"
        menu={navigation}
        classes={["has-background-black", "is-dark"]}
        // styles={{
        //   backgroundImage: `url("${require("../images/strategy/bg-strategy.jpg")}")`,
        // }}
      />
      <Hero size="medium">
        <HeroBody>
          <Container>
            {/* <Heading tag="h1" text="Intro" /> */}
            <p className="is-size-3">
              "You can't connect the dots looking forward;
              <br />
              you can only connect them looking backwards."
            </p>
          </Container>
        </HeroBody>
      </Hero>
      <Section>
        <Container>
          <Content>
            <Video src={require("../images/strategy/strategy.mp4")} />
          </Content>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className="columns">
            <div className="column is-half">
              <Content classes={["strategy__content"]}>
                <Heading tag="h3" type="subtitle" text="Vision" />
                <p>
                  Increase in consumption of a timeless and inspiring lifestyle
                  around the world.
                </p>
                <Image
                  src={require("../images/strategy/entertailer.png")}
                  alt="Entertailer"
                />
              </Content>
            </div>
            <div className="column is-half">
              <Content classes={["strategy__content"]}>
                <Heading tag="h3" type="subtitle" text="Mission" />
                <p>
                  Our platform purpose is to engage seekers, activate lovers and
                  improve buyers' loyalty towards fashion, art and design
                  contents and products.
                </p>
                <Image
                  src={require("../images/strategy/yoox-com.png")}
                  alt="Yoox.com"
                />
              </Content>
            </div>
          </div>
          <Content />
        </Container>
      </Section>
    </Layout>
  )
}

export default StrategyPage
